import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import dash from "../views/61aa41c7-f8e9-4b1a-bb6d-e7f888aacaec.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/61aa41c7-f8e9-4b1a-bb6d-e7f888aacaec",
    name: "dash",
    component: dash,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
