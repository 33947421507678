<template>
  <div id="data-an" class="py-5">
    <div class="container">
      <h1 class="text-4xl mb-4">Lead Data</h1>
      <section class="table-data p-3 border">
        <table class="table table-striped table-hover">
          <thead>
            <th class="pb-4">#</th>
            <th class="pb-4">Name</th>
            <th class="pb-4">City</th>
            <th class="pb-4">Email</th>
            <th class="pb-4">phone</th>
            <th class="pb-4">reserve ?</th>
            <th class="pb-4">Reserve Date</th>
            <th class="pb-4">Reserve Time</th>
          </thead>
          <tbody>
            <tr v-for="(customer, index) in customers" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ customer.name }}</td>
              <td>{{ customer.city }}</td>
              <td>{{ customer.email }}</td>
              <td>{{ customer.phone }}</td>
              <td>{{ customer.reserve }}</td>
              <td>{{ customer.date }}</td>
              <td>{{ customer.time }}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      customers: [],
    };
  },
  methods: {
    getCustomers() {
      var config = {
        method: "get",
        url: "https://leadapi.isnad-iq.com/api/forms",
        headers: {
          "Content-Type": "application/json",
        },
        data: this.postData,
      };
      axios(config)
        .then((response) => {
          this.customers = response.data.data;
          console.log(response.data.data);
          console.log(this.customers.data);
        })
        .catch((error) => {
          var setError = "";
          console.log(error.response);
          for (var key in error.response.data) {
            setError += `-${error.response.data[key][0]}`;
          }
          this.pennding = false;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: setError,
            footer: "",
          });
        });
    },
  },
  mounted() {
    this.getCustomers();
  },
};
</script>
