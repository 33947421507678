import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: localStorage.getItem("isnad-lang") || "ar",
  },
  getters: {
    lang: (state) => state.lang,
  },
  mutations: {
    changeLang(state) {
      if (state.lang == "ar") {
        state.lang = "en";
        localStorage.setItem("isnad-lang", "en");
      } else {
        state.lang = "ar";
        localStorage.setItem("isnad-lang", "ar");
      }
    },
  },
  actions: {},
  modules: {},
});
