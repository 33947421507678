<template>
  <div
    class="input-content"
    :class="[active ? 'active' : '']"
    @click="activeInput()"
  >
    <input
      :type="type"
      :id="lfor"
      @focus="focusField($event)"
      @blur="blurField($event)"
      class="field eff"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <label :for="lfor" class="input-placeholder">
      {{ placehold }}
    </label>
    <span class="icon" v-if="icon !== ''">
      <i :class="icon"></i>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    value: String,
    type: String,
    placehold: String,
    lfor: String,
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    focusField() {
      this.active = true;
    },
    blurField(event) {
      if (event.target.value.length == 0) {
        this.active = false;
      }
    },
    activeInput() {
      this.$el.firstElementChild.focus();
      console.log();
    },
  },
};
</script>
