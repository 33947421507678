<template>
  <div id="custumer_lead" :class="getLang == 'ar' ? 'rtl' : 'ltr'">
    <div class="lang">
      <button
        @click="$store.commit('changeLang')"
        v-text="contentPage.lang[getLang]"
      ></button>
    </div>
    <div class="">
      <div class="lead_content">
        <div class="lead-form">
          <p class="hint-p" v-text="contentPage.discription[getLang]"></p>
          <form action="">
            <field
              lfor="Full_Name"
              icon="fa-solid fa-user-tie"
              :placehold="contentPage.form.name[getLang] + '*'"
              type="text"
              v-model.lazy="postData.name"
            />
            <field
              lfor="City"
              icon="fa-solid fa-city"
              :placehold="contentPage.form.city[getLang] + '*'"
              type="text"
              v-model="postData.city"
            />
            <field
              lfor="Phone_Number"
              icon="fa-solid fa-phone"
              :placehold="contentPage.form.phone[getLang] + '*'"
              type="phone"
              v-model="postData.phone"
            />
            <field
              lfor="Email"
              icon="fa-solid fa-envelope"
              :placehold="contentPage.form.email[getLang]"
              type="email"
              v-model="postData.email"
            />
            <div
              class="input-content"
              :class="getLang == 'ar' ? 't-right' : ''"
            >
              <input
                v-model="postData.reserve"
                @change="deleteDate()"
                id="bookTime"
                type="checkbox"
                class="check"
              />
              <label
                for="bookTime"
                class=""
                v-text="contentPage.form.reserve[getLang]"
              ></label>
            </div>
            <div :class="[postData.reserve ? 'active' : '', 'booking_date']">
              <field
                lfor="Day"
                icon=""
                :placehold="contentPage.form.date[getLang] + '*'"
                type="date"
                v-model="postData.date"
              />
              <field
                lfor="Hour"
                icon=""
                :placehold="contentPage.form.time[getLang] + '*'"
                type="time"
                v-model="postData.time"
              />
            </div>
            <div class="form-controll">
              <button
                :class="[pennding ? 'pendding' : '', 'duration-500']"
                @click.prevent="sendData()"
              >
                {{ contentPage.form.submit[getLang] }}
                <i class="fa-solid fa-paper-plane" v-if="!pennding"></i>
                <i class="fa-solid fa-spinner loading-icon" v-else></i>
              </button>
            </div>
          </form>
          <contact-info id="form-contact" class="" />
        </div>
        <div class="lead_info relative">
          <div class="info-content">
            <img
              :src="
                getLang == 'ar' ? './images/logo_ar.png' : './images/logo.png'
              "
              class="logo mx-auto"
              alt=""
            />
            <h2 class="lead_info_title">
              <span v-if="getLang == 'en'">
                We <span class="title-hint">Support</span> You
              </span>
              <span v-else>
                نــحن <span class="title-hint"> لــك </span> سـند
              </span>
            </h2>
          </div>
          <div class="lead_contact">
            <img class="pattren" src="@/assets/images/pattern.png" alt="" />
            <contact-info id="info-contact" class="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Field from "@/components/FieldInput.vue";
import contactInfo from "@/components/ContactInfo.vue";
import content from "@/assets/js/content.js";

export default {
  name: "IndexPage",
  components: {
    Field,
    contactInfo,
  },
  data() {
    return {
      pennding: false,
      contentPage: {},
      postData: {
        name: "",
        city: "",
        phone: "",
        email: "",
        reserve: false,
        date: "",
        time: "",
        latitude: "",
        longitude: "",
      },
    };
  },
  computed: {
    getLang() {
      return this.$store.state.lang;
    },
  },
  methods: {
    deleteDate() {
      if (!this.postData.reserve) {
        this.postData.date = null;
        this.postData.time = null;
        console.log(this.postData);
      }
    },
    sendData() {
      if (!this.postData.reserve) {
        delete this.postData.date;
        delete this.postData.time;
      }
      this.pennding = true;
      var config = {
        method: "post",
        url: "https://leadapi.isnad-iq.com/api/forms",
        headers: {
          "Content-Type": "application/json",
        },
        data: this.postData,
      };
      axios(config)
        .then(() => {
          this.pennding = false;
          Swal.fire({
            position: "center",
            icon: "success",
            title: this.contentPage.responseMsg[this.getLang],
            showConfirmButton: false,
            timer: 2000,
          });
          this.postData = {
            name: "",
            city: "",
            phone: "",
            email: "",
            reserve: false,
            date: "",
            time: "",
            latitude: "",
            longitude: "",
          };
        })
        .catch((error) => {
          var setError = "";
          console.log(error.response);
          for (var key in error.response.data) {
            setError += `-${error.response.data[key][0]}`;
          }
          this.pennding = false;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: setError,
            showCloseButton: false,
            showCancelButton: false,
            footer: "",
          });
        });
    },
  },
  watch: {
    name(value) {
      console.log("value");
      console.log(value);
    },
  },
  mounted() {
    this.contentPage = content;
    navigator.geolocation.getCurrentPosition((position) => {
      this.postData.latitude = position.coords.latitude;
      this.postData.longitude = position.coords.longitude;
    });

    console.log(content);
    console.log(this.contentPage);
  },
};
</script>
