var content = {
  lang: {
    en: "عـــربي",
    ar: "English",
  },
  discription: {
    en: "Fill in the information form to enable our employees to communicate with you and submit required attribution",
    ar: "املئ استمارة المعلومات ليتمكن موظفينا من التواصل معك وتقديم الاسناد اللازم",
  },
  responseMsg: {
    en: "Your Info has been sended",
    ar: "تم إرسال معلوماتك",
  },
  form: {
    name: {
      en: "Full Name",
      ar: "الاســم الكامل",
    },
    city: {
      en: "City",
      ar: "الــمدينة",
    },
    phone: {
      en: "Phone Number",
      ar: "رقم الهاتف",
    },
    email: {
      en: "Email",
      ar: "البريد الالكتروني",
    },
    submit: {
      en: "Send",
      ar: "أرســال",
    },
    reserve: {
      en: "Book A Time ?",
      ar: "حــجز موعد",
    },
    date: {
      en: "Day",
      ar: "يوم الحجز",
    },
    time: {
      en: "Time",
      ar: "ساعة الحجز",
    },
  },
};

export default content;
